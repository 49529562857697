import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

interface WindowWidths {
  mobileWidthDetected: boolean | null;
  desktopWidthDetected: boolean | null;
}

// These values are null on the initial render, and they will cause a re-render when updated.
// Therefore, do not use this hook unless that is the behavior you need.

// For layouts, try to use CSS.
// For device-related features, use the useIsMobileDevice hook.
// For ads, use the ResponsiveNonProGoogleAd component - the sizes prop and the hide prop can be used to control the ad's visibility.

export default function useIsMobile(
  { desktopBreakpoint } = { desktopBreakpoint: 900 }
): WindowWidths {
  const [state, setState] = useState<WindowWidths>({
    mobileWidthDetected: null,
    desktopWidthDetected: null,
  });

  useEffect(() => {
    const setDeviceWidth = () => {
      setState({
        mobileWidthDetected: window.innerWidth < desktopBreakpoint,
        desktopWidthDetected: window.innerWidth >= desktopBreakpoint,
      });
    };

    setDeviceWidth();

    const onResize = debounce(setDeviceWidth, 500);

    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [desktopBreakpoint]);

  return state;
}
